@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #ffffff;
}

@layer base {
    html {
        @apply scroll-smooth
    }
    body {
        /*@apply bg-[#edebe2]*/
        @apply bg-[--primary-color] dark:bg-gray-900
    }

    .serviceBox {
        transition: 0.7s ;
    }


@layer utilities{
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
}


}